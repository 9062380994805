import { Contracts } from '../types';
import * as arbitrum from '@/assets/data/contracts/arbitrum.json';

const contracts: Contracts = {
  merkleRedeem: '0x6bd0B17713aaa29A2d7c9A39dDc120114f9fD809',
  merkleOrchard: '0x751A0bC0e3f75b38e01Cf25bFCE7fF36DE1C87DE',
  merkleOrchardV2: arbitrum.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: '0x6207ed574152496c9B072C24FD87cE9cd9E17320',
  vault: arbitrum.Vault,
  weightedPoolFactory: arbitrum.WeightedPoolFactory,
  stablePoolFactory: '0x2433477A10FC5d31B9513C638F19eE85CaED53Fd',
  lidoRelayer: '',
  balancerHelpers: arbitrum.BalancerHelpers,
  batchRelayer: arbitrum.BalancerRelayer,
  gaugeFactory: arbitrum.ChildChainLiquidityGaugeFactory,
  balancerMinter: arbitrum.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x81cFAE226343B24BA12EC6521Db2C79E7aeeb310',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: arbitrum.ChildChainGaugeRewardHelper,
  gaugeWorkingBalanceHelper: arbitrum.ChildChainGaugeWorkingBalanceHelper,

  distributionAssessor: '0xaD103f85571b43B8D81dA935471240Ab7Afe2a74',
  securitizationPoolValueService: '0x663960FEE8b2883Dd50aE3D0ae4333F6af58A9b6',
  acceptedInvoiceToken: '0x56C247cF458905813a2d2B0A12f88Ce11cce938b',
  distributionOperator: '0x15D607b81518ddfE8735FfFd6A2b0E699ef26d55',

  untangled: {
    securitizationManagementProject:
      '0xe9dc50E02ECe436B33E769386Ebcf916a912a4a5',
    securitizationPoolValueService:
      '0xB52dD49644e1c7Db65c1b2B9a2A777C4d1EaE32e',
    noteTokenVault: '0x608a8C14DE2085f98D931F6562a5D774dE40B91B',
    distributionAssessor: '0xB52dD49644e1c7Db65c1b2B9a2A777C4d1EaE32e', // TODO Should be removed
    distributionTranche: '0xDb4480939085542d90FC7e95613b8c22Eb371A1f', // TODO should be removed
    distributionOperator: '0x15D607b81518ddfE8735FfFd6A2b0E699ef26d55', // TODO safe to be removed
    uniqueIdentity: '0x0F4bB05c7c804E75805Ed41B601679376de61E2f',
    loanAssetToken: '0xEcD8133a1B9b90c651Cef137D1cf62581D0267a9',
    vault: '0x000000000000000000000000000000000000000000',
  },
};

export default contracts;
