import debounce from 'lodash/debounce';
import { utils } from 'ethers';

import {
  getInjectedProvider,
  hasInjectedProvider,
} from '@/services/web3/connectors/metamask/metamask.connector';

import { getWeb3Provider } from '@/dependencies/wallets/Web3Provider';
import { useWallets } from '@/providers/wallet.provider';
import { configService } from '../config/config.service';
import { rpcProviderService } from '../rpc-provider/rpc-provider.service';
import { switchToAppNetwork } from './utils/helpers';
import useAlerts, { AlertPriority, AlertType } from '@/composables/useAlerts';
import { useI18n } from 'vue-i18n';
import { Network } from '@/lib/config';
import { JsonRpcSigner } from '@ethersproject/providers';
import useNetwork from '@/composables/useNetwork';
import { hardRedirectTo } from '@/plugins/router/nav-guards';

/** STATE */
const blockNumber = ref(0);
const isWalletSelectVisible = ref(false);
const isConnectAccountVisible = ref(false);

/** MUTATIONS */
function setBlockNumber(n: number): void {
  blockNumber.value = n;
}

/** INIT STATE */
rpcProviderService.initBlockListener(setBlockNumber);

const toggleWalletSelectModal = (value?: boolean) => {
  isWalletSelectVisible.value = value ?? !isWalletSelectVisible.value;
};
const toggleConnectAccountModal = (value?: boolean) => {
  isConnectAccountVisible.value = value ?? !isConnectAccountVisible.value;
};
const delayedToggleWalletSelectModal = debounce(toggleWalletSelectModal, 200);

export default function useWeb3() {
  const { t } = useI18n();
  const {
    account,
    chainId,
    connector,
    provider,
    walletState,
    signer,
    disconnectWallet,
    connectWallet,
    isBlocked,
    smartAccount,
  } = useWallets();
  const { networkId, getNetworkChangeUrl } = useNetwork();
  const { addAlert, removeAlert } = useAlerts();
  const appNetworkConfig = configService.network;

  // COMPUTED REFS + COMPUTED REFS
  const userNetworkConfig = computed(() => {
    try {
      if (chainId.value) {
        return configService.getNetworkConfig(chainId.value);
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  });
  const isWalletReady = computed(() => walletState.value === 'connected');
  const isWalletConnecting = computed(() => walletState.value === 'connecting');
  const isWalletDisconnected = computed(
    () => walletState.value === 'disconnected'
  );

  const isMismatchedNetwork = computed(() => {
    return (
      isWalletReady.value &&
      userNetworkConfig.value?.key !== appNetworkConfig.key
    );
  });
  const isUnsupportedNetwork = computed(() => {
    return isWalletReady.value && userNetworkConfig.value === null;
  });
  const explorerLinks = {
    txLink: (txHash: string) =>
      `${configService.network.explorer}/tx/${txHash}`,
    addressLink: (address: string) =>
      `${configService.network.explorer}/address/${address}`,
    tokenLink: (address: string) =>
      `${configService.network.explorer}/token/${address}`,
  };

  // METHODS
  const Web3Provider = getWeb3Provider();
  const getProvider = () => new Web3Provider(provider.value as any, 'any');
  const getSigner = () => {
    if (smartAccount.value) {
      return smartAccount.value.getSigner();
    }
    return getProvider().getSigner();
  };
  const connectToAppNetwork = () => switchToAppNetwork(provider.value as any);

  function startConnectWithInjectedProvider(): void {
    if (hasInjectedProvider() && getInjectedProvider().isCoinbaseWallet) {
      // Open wallet select modal because even if there's injected provider,
      // user might want to reject it and use another wallet.
      // If user has already accepted the injected provider, modal will be closed after
      // wallet is connected
      delayedToggleWalletSelectModal();
      // Immediately try to connect with injected provider
      connectWallet('metamask').then(() => {
        // If wallet is not ready, keep the modal open
        if (isWalletDisconnected.value) return;
        // When wallet is connected, close modal
        // and clear the delayed toggle timeout so the modal doesn't open
        delayedToggleWalletSelectModal.flush();
        toggleWalletSelectModal(false);
      });
    } else {
      // If there's no injected provider, open the modal immediately
      toggleWalletSelectModal();
    }
  }

  function connectSmartAccount(accountName = '') {
    return new Promise((resolve, reject) => {
      connectWallet('smartaccount', accountName)
        .then(() => {
          if (isWalletDisconnected.value) return;
          toggleConnectAccountModal(false);
          resolve(true);
        })
        .catch(err => reject(err));
    });
  }

  function hideNetworkAlert() {
    removeAlert('network-mismatch');
  }

  const checkAddressIsSmartContract = async (
    address: string
  ): Promise<boolean> => {
    if (!address || smartAccount.value) {
      return false;
    }

    const signer = getSigner() as JsonRpcSigner;
    const bytecode = await signer.provider.getCode(account.value);
    return Boolean(bytecode && utils.hexStripZeros(bytecode) !== '0x');
  };

  function checkAndShowMismatchNetworkAlert(chainForChecking?: Network) {
    if (
      isWalletReady.value &&
      chainForChecking &&
      (chainId.value !== chainForChecking ||
        networkId.value !== chainForChecking)
    ) {
      const networkConfig = configService.getNetworkConfig(chainForChecking);

      addAlert({
        id: 'network-mismatch',
        label: t('networkMismatch', [networkConfig.name]),
        type: AlertType.ERROR,
        persistent: true,
        action: () => {
          if (chainId.value !== chainForChecking) {
            switchToAppNetwork(provider.value, chainForChecking);
          }
          if (networkId.value !== chainForChecking) {
            hardRedirectTo(getNetworkChangeUrl(networkConfig.slug));
          }
        },
        actionLabel: t('switchNetwork'),
        priority: AlertPriority.HIGH,
      });
    } else {
      hideNetworkAlert();
    }
  }

  function checkIsUnsupportedNetwork() {
    if (
      chainId.value &&
      (isUnsupportedNetwork.value || isMismatchedNetwork.value)
    ) {
      addAlert({
        id: 'network-mismatch',
        label: t('networkMismatch', [appNetworkConfig.name]),
        type: AlertType.ERROR,
        persistent: true,
        action: connectToAppNetwork,
        actionLabel: t('switchNetwork'),
        priority: AlertPriority.HIGH,
      });
    } else {
      hideNetworkAlert();
    }
  }

  return {
    // refs
    account,
    chainId,
    profile: ref(undefined),
    connector,
    provider,
    walletState,
    userNetworkConfig,
    appNetworkConfig,
    isLoadingProfile: ref(false),
    isWalletReady,
    isWalletSelectVisible,
    isConnectAccountVisible,
    isMismatchedNetwork,
    isUnsupportedNetwork,
    explorerLinks,
    signer,
    blockNumber,
    isWalletConnecting,
    isBlocked,
    smartAccount,

    // methods
    connectSmartAccount,
    connectWallet,
    connectToAppNetwork,
    getProvider,
    getSigner,
    disconnectWallet,
    toggleWalletSelectModal,
    toggleConnectAccountModal,
    startConnectWithInjectedProvider,
    setBlockNumber,
    hideNetworkAlert,
    checkAndShowMismatchNetworkAlert,
    checkIsUnsupportedNetwork,
    checkAddressIsSmartContract,
  };
}
