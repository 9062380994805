import { handleDependencyError } from '..';

let _connecor;

/**
 * Uses the real walletLink connector instance by default but allows injecting walletLink connector mocks from tests
 */
export async function initSmartAccountConnector() {
  if (!_connecor) {
    // Lazy load dependency to reduce initial bundle size
    const { SmartAccountConnector } = await import(
      '@/services/web3/connectors/smartAccount/smartAccount.connector'
    );
    _connecor = SmartAccountConnector;
  }
}

export async function initSmartAccountConnectorForTesting(connector) {
  _connecor = connector;
}

export function getSmartAccountConnector() {
  if (!_connecor) {
    handleDependencyError('SmartAccountConnector');
  }
  return _connecor;
}
