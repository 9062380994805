import instance from '../services/api/untangled-axiosv2';
import useWeb3 from '@/services/web3/useWeb3';
import symbolKeys from '@/constants/symbol.keys';
import { safeInject } from '@/providers/inject';
import { getUserTypeFromUIDQuery } from '@/composables/queries/useGetUserTypeFromUIDQuery';
import _ from 'lodash';
import moment from 'moment';
import { checkAuthTokenValid, setAuthToken } from '@/services/auth';

export enum VerificationStatus {
  PENDING = 0,
  ACCEPTED = 1,
  REJECTED = 2,
  STATUS_CHANGED = 3,
  DELETED = 4,
  RECEIVED = 5,
  REVIEWING = 6,
}
export enum KycUserTypes {
  NON_US_INDIVIDUAL = 'NON_US_INDIVIDUAL',
  US_INDIVIDUAL = 'US_INDIVIDUAL',
  US_BUSINESS = 'US_BUSINESS',
  NON_US_BUSINESS = 'NON_US_BUSINESS',
  BUSINESS = 'BUSINESS',
  INDIVIDUAL = 'INDIVIDUAL',
  NONE = 'NONE',
}
export enum KyiInvestorTypes {
  US_INVESTOR = 'US_INVESTOR',
  NON_US_INVESTOR = 'NON_US_INVESTOR',
}
export enum KyiNonUSInvestorSteps {
  SelectorInvestorType = 1,
  LinkWallet = 2,
  SelfCertificate = 3,
  IdentityVerification = 4,
  EmailVerification = 5,
  GenerateUID = 6,
}
export enum KyiUSInvestorSteps {
  SelectorInvestorType = 1,
  LinkWallet = 2,
  SelfCertificate = 3,
  IdentityVerification = 4,
  Accreditation = 5,
  GenerateUID = 6,
}
export enum KyiBusinessSteps {
  SelectorInvestorType = 1,
  LinkWallet = 2,
  BusinessInformation = 3,
  AuthorizedSignerVerification = 4,
  CoporateDocumentation = 5,
  GenerateUID = 6,
}
export enum SelftCertificateTypes {
  US_ACCREDITED_INVESTOR = 1,
  UK_HIGH_NETWORTH_INDIVIDUAL = 2,
  UK_SELF_CERT_SOPHISTICATED_INDIVIDUAL = 3,
  ACCREDITED_PROFESSIONAL_INVESTOR = 4,
}
export enum EmailVerificationStatus {
  PENDING = 0,
  ACCEPTED = 1,
}

export enum BanStatus {
  FREE = 0,
  BANNED = 1,
}

export interface KycInfo {
  id: number | null;
  userType: KycUserTypes | null;
  reference: string | null;
  address: string | null;
  verificationUrl: string | null;
  status: VerificationStatus | null;
  stepType:
    | KyiNonUSInvestorSteps
    | KyiUSInvestorSteps
    | KyiBusinessSteps
    | null;
  fullName: string | null;
  email: string | null;
  emailStatus: EmailVerificationStatus | null;
  dateOfBirth: string | null;
  countryCitizenship: string | null;
  countryResidence: string | null;
  selfCertificateType: SelftCertificateTypes | null;
  legalEntityName: any | null;
  incorporationCountry: any | null;
  registrationNumber: any | null;
  registeredAddress: any | null;
  beneficialOwners:
    | {
        name: string;
        dateOfBirth: string;
        citizenCountry: string;
        residenceCountry: string;
      }[]
    | null;
  residentalAddress: string | null;
  businessTitle: string | null;
  banStatus: BanStatus;
}

export interface Country {
  value: string;
  text: string;
}

export const kycInfo = () => {
  const { account, getSigner, checkAddressIsSmartContract, smartAccount } =
    useWeb3();

  const loading = ref(false);
  const progress = ref(0);
  const kycInfo = ref<KycInfo | null>(null);
  const countries = ref<Country[]>([]);
  const userType = ref<KycUserTypes>();
  const banStatus = ref<BanStatus>();

  const isUS = computed(() => {
    return userType.value === KycUserTypes.US_INDIVIDUAL;
  });

  const isBusiness = computed(
    () =>
      userType.value &&
      [
        KycUserTypes.BUSINESS,
        KycUserTypes.US_BUSINESS,
        KycUserTypes.NON_US_BUSINESS,
      ].includes(userType.value)
  );

  const requiredSteps = computed(() => [
    {
      label: 'Link Wallet',
      step: KyiNonUSInvestorSteps.LinkWallet,
      component: defineAsyncComponent(
        () => import('@/components/contextual/pages/onboarding/LinkWallet.vue')
      ),
    },
    {
      label: 'Select investor type',
      step: KyiNonUSInvestorSteps.SelectorInvestorType,
      component: defineAsyncComponent(
        () =>
          import('@/components/contextual/pages/onboarding/InvestorType.vue')
      ),
    },
  ]);

  const businessSteps = computed(() => [
    {
      title: 'Provide information about business',
      label: 'Business Information',
      step: KyiBusinessSteps.BusinessInformation,
      component: defineAsyncComponent(
        () =>
          import(
            '@/components/contextual/pages/onboarding/Business/BusinessInformation.vue'
          )
      ),
    },
    {
      label: 'Authorized signer verification',
      step: KyiBusinessSteps.AuthorizedSignerVerification,
      component: defineAsyncComponent(
        () =>
          import(
            '@/components/contextual/pages/onboarding/Business/AuthorizedSigner.vue'
          )
      ),
    },
    {
      label: 'Detailed due diligence',
      step: KyiBusinessSteps.CoporateDocumentation,
      component: defineAsyncComponent(
        () =>
          import(
            '@/components/contextual/pages/onboarding/Business/CorporateDoc.vue'
          )
      ),
    },
    {
      label: 'Status',
      title: '',
      step: KyiBusinessSteps.GenerateUID,
      component: defineAsyncComponent(
        () => import('@/components/contextual/pages/onboarding/Status.vue')
      ),
    },
  ]);

  const userSteps = computed(() => [
    {
      label: 'Opt-out',
      step: KyiNonUSInvestorSteps.SelfCertificate,
      component: defineAsyncComponent(
        () => import('@/components/contextual/pages/onboarding/OptOut.vue')
      ),
    },
    {
      label: 'Identity verification',
      step: KyiNonUSInvestorSteps.IdentityVerification,
      component: defineAsyncComponent(
        () => import('@/components/contextual/pages/onboarding/Identity.vue')
      ),
    },
    ...(isUS.value
      ? [
          {
            title: '',
            label: 'Accreditation',
            step: KyiUSInvestorSteps.Accreditation,
            component: defineAsyncComponent(
              () =>
                import(
                  '@/components/contextual/pages/onboarding/US/Accreditation.vue'
                )
            ),
          },
        ]
      : [
          {
            title: 'Email verification',
            label: 'Email verification',
            step: KyiNonUSInvestorSteps.EmailVerification,
            component: defineAsyncComponent(
              () =>
                import(
                  '@/components/contextual/pages/onboarding/EmailVerification.vue'
                )
            ),
          },
        ]),
    {
      label: 'Status',
      title: '',
      step: KyiNonUSInvestorSteps.GenerateUID,
      component: defineAsyncComponent(
        () => import('@/components/contextual/pages/onboarding/Status.vue')
      ),
    },
  ]);

  const steps = computed(() => {
    return [
      ...requiredSteps.value,
      ...(isBusiness.value ? businessSteps.value : userSteps.value),
    ];
  });

  const stepToIndex = computed(() => {
    const a = _.reduce(
      steps.value,
      (prev, curr, index) => {
        prev[curr.step] = index;
        return prev;
      },
      {} as Record<number, number>
    );

    return a;
  });

  const calcIndex = (kyc: KycInfo | null) => {
    if (_.isNil(kyc?.stepType)) {
      return stepToIndex.value[KyiNonUSInvestorSteps.LinkWallet];
    }

    if (kyc.stepType === KyiNonUSInvestorSteps.LinkWallet) {
      return stepToIndex.value[KyiNonUSInvestorSteps.SelectorInvestorType];
    }

    const isLastStep = (() => {
      if (isBusiness.value) {
        return kyc.stepType === KyiBusinessSteps.CoporateDocumentation;
      }
      if (isUS.value) {
        return kyc.stepType === KyiUSInvestorSteps.Accreditation;
      }
      return kyc.stepType === KyiNonUSInvestorSteps.EmailVerification;
    })();

    const offset =
      isLastStep && !_.isNil(kyc.status)
        ? {
            [VerificationStatus.PENDING]: 0,
            [VerificationStatus.ACCEPTED]: 1,
            [VerificationStatus.REJECTED]: 1,
            [VerificationStatus.REVIEWING]: 1,
          }[kyc.status]
        : +(kyc?.status === VerificationStatus.ACCEPTED);

    let step = kyc.stepType + offset;

    // Add extra step when last step is EmailVerification (for non-US investor)
    const addExtraStep =
      !isBusiness.value &&
      !isUS.value &&
      step === KyiNonUSInvestorSteps.EmailVerification &&
      kyc.emailStatus === EmailVerificationStatus.ACCEPTED;

    if (addExtraStep) {
      step += 1;
    }

    const index = stepToIndex.value[step];

    return index;
  };

  const prevIndex = computed(() => {
    return calcIndex(kycInfo.value);
  });
  const activeIndex = ref(0);

  const activeIndexDone = computed(() => {
    return prevIndex.value > activeIndex.value;
  });

  const fetchInfo = async () => {
    const { data } = await instance.get<{
      data: { kyc: KycInfo | null };
    }>('/api/v1/kyi/info');

    return data.data.kyc;
  };

  const fetchCountries = async () => {
    const { data } = await instance.get<{
      data: { countries: Record<string, string> };
    }>('/api/v1/kyi/countries');

    const arr: Country[] = [];

    for (const value in data.data.countries) {
      const text = data.data.countries[value];
      arr.push({ value: value.toUpperCase(), text });
    }

    countries.value = arr;
  };

  const getProgress = async (addr: string) => {
    if (!addr) {
      progress.value = 0;
      return;
    }
    const [process1, process2] = await Promise.all([
      instance
        .get<{ data: { status: number } }>(`/api/v1/kyi/status/${addr}`)
        .then(r => r.data.data.status)
        .catch(() => 0),
      getUserTypeFromUIDQuery(addr)
        .then(r => +Boolean(r.userType))
        .catch(() => 0),
    ]);
    progress.value = process1 + process2;
  };

  watch(account, getProgress);

  const updateKycInfo = (kyc: KycInfo | null, updateStep = false) => {
    kycInfo.value = kyc;
    userType.value = kyc?.userType ?? undefined;
    banStatus.value = kyc?.banStatus;

    updateStep && (activeIndex.value = calcIndex(kyc));
  };

  const reload = async (opts?: {
    updateStep?: boolean;
    immediate?: boolean;
    showLoading?: boolean;
  }) => {
    let kyc: KycInfo | null = null;

    try {
      if (opts?.showLoading) {
        loading.value = true;
      }
      kyc = await fetchInfo();
      if (opts?.immediate) {
        updateKycInfo(kyc, opts.updateStep);
      }
    } catch (error) {
      //
    } finally {
      loading.value = false;
    }

    return kyc;
  };

  const login = async (address: string) => {
    const message = `Welcome to Untangled!\n\nClick "Sign" to sign in. No password needed!\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nWallet address: ${address}`;
    const signer = getSigner();

    const isTokenStillValid = checkAuthTokenValid(address);

    if (isTokenStillValid) return;
    const isMultisigWallet = await checkAddressIsSmartContract(address);
    const signature = await signer.signMessage(message);

    const signatureType = (() => {
      if (isMultisigWallet) {
        return 'multiSign';
      }

      if (smartAccount.value) {
        return 'smartAccount';
      }
      // TODO: Add in SmartAccount here
      return 'ethSign';
    })();

    const { data } = await instance.post<{ data: { token: string } }>(
      '/api/authentication/login',
      {
        message,
        signature,
        isMultisig: isMultisigWallet, // TODO: Remove this field later
        walletAddress: address,
        type: signatureType,
      }
    );

    setAuthToken({
      token: data.data.token,
      exp: moment().add(1, 'week').toISOString(),
      address,
    });
  };

  const resetSteps = () => {
    updateKycInfo(null, true);
  };

  const checkStepCompleted = async (
    stepType: KycInfo['stepType'],
    shouldUpdateKycInfo = true
  ): Promise<boolean> => {
    const kyc = await reload({ immediate: shouldUpdateKycInfo });

    if (!kyc || !kyc.stepType || !stepType) return false;

    return (
      kyc.stepType > stepType ||
      (kyc.stepType === stepType && kyc.status === VerificationStatus.ACCEPTED)
    );
  };

  return {
    activeIndex,
    prevIndex,
    activeIndexDone,
    steps,
    progress,
    loading,
    countries,
    reload,
    fetchInfo,
    getProgress,
    kycInfo,
    userType,
    isUS,
    isBusiness,
    fetchCountries,
    login,
    resetSteps,
    checkStepCompleted,
    updateKycInfo,
    banStatus,
  };
};

export type KycInfoResponse = ReturnType<typeof kycInfo>;
export const KycInfoProviderSymbol: InjectionKey<KycInfoResponse> = Symbol(
  symbolKeys.Providers.KycInfo
);

export function provideKycInfo(): KycInfoResponse {
  const provided = kycInfo();
  provide(KycInfoProviderSymbol, provided);
  return provided;
}

export const useKycInfo = (): KycInfoResponse => {
  return safeInject(KycInfoProviderSymbol);
};
