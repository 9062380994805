import {
  EthersContract,
  getEthersContract,
} from '@/dependencies/EthersContract';
import { rpcProviderService } from '@/services/rpc-provider/rpc-provider.service';
import UniqueIdentityABI from '@/lib/abi/UniqueIdentity.json';
import { walletService as walletServiceInstance } from '@/services/web3/wallet.service';
import { getAddress } from '@ethersproject/address';
import { parseEther } from '@ethersproject/units';
import { encodeFunctionData } from 'viem';
import BigNumber from 'bignumber.js';

export class UniqueIdentity {
  instance: EthersContract;

  constructor(
    public readonly address: string,
    private readonly provider = rpcProviderService.jsonProvider,
    private readonly abi = UniqueIdentityABI,
    private readonly walletService = walletServiceInstance
  ) {
    const Contract = getEthersContract();
    this.instance = new Contract(this.address, this.abi, this.provider);
  }

  async mint(
    id: number,
    expiresAt: number,
    signature: string,
    walletSigner: string
  ) {
    // For SmartAccount
    if (this.walletService?.smartAccountProvider?.value) {
      const smartAccount = this.walletService.smartAccountProvider.value;

      const encodedCall = encodeFunctionData({
        abi: this.abi,
        functionName: 'mint',
        args: [id, expiresAt, signature],
      });

      const userOpResponse = await smartAccount.sendTransaction({
        to: this.address,
        data: encodedCall,
        value: parseEther('0.00083'),
      });
      const { transactionHash } = await userOpResponse.waitForTxHash();
      return { hash: transactionHash };
    }

    // For normal wallets
    const signer = this.walletService.userProvider.value.getSigner();
    const contractWithSigner = this.instance.connect(signer);
    return await contractWithSigner.mint(id, expiresAt, signature, {
      from: walletSigner,
      value: parseEther('0.00083'),
    });
  }

  async balanceOfBatch(
    accounts: string[],
    ids: number[] = [0]
  ): Promise<BigNumber[]> {
    return this.instance.balanceOfBatch(
      accounts.map(v => getAddress(v)),
      ids
    );
  }

  async nonces(walletAddress: string) {
    return this.instance.nonces(walletAddress);
  }
}
