import { Contracts } from '../types';
import * as arbitrum from '@/assets/data/contracts/arbitrum.json';

const contracts: Contracts = {
  merkleRedeem: '0x6bd0B17713aaa29A2d7c9A39dDc120114f9fD809',
  merkleOrchard: '0x751A0bC0e3f75b38e01Cf25bFCE7fF36DE1C87DE',
  merkleOrchardV2: arbitrum.MerkleOrchard,
  multicall: '0xcA11bde05977b3631167028862bE2a173976CA11',
  authorizer: '0x6207ed574152496c9B072C24FD87cE9cd9E17320',
  vault: arbitrum.Vault,
  weightedPoolFactory: arbitrum.WeightedPoolFactory,
  stablePoolFactory: '0x2433477A10FC5d31B9513C638F19eE85CaED53Fd',
  lidoRelayer: '',
  balancerHelpers: arbitrum.BalancerHelpers,
  batchRelayer: arbitrum.BalancerRelayer,
  gaugeFactory: arbitrum.ChildChainLiquidityGaugeFactory,
  balancerMinter: arbitrum.L2BalancerPseudoMinter,
  gaugeController: '',
  tokenAdmin: '',
  veBAL: '',
  veDelegationProxy: '0x81cFAE226343B24BA12EC6521Db2C79E7aeeb310',
  veBALHelpers: '',
  feeDistributor: '',
  feeDistributorDeprecated: '',
  faucet: '',
  gaugeRewardsHelper: arbitrum.ChildChainGaugeRewardHelper,
  gaugeWorkingBalanceHelper: arbitrum.ChildChainGaugeWorkingBalanceHelper,

  distributionAssessor: '',
  securitizationPoolValueService: '0x704E0e57cbB2bbD364Dc858eE4f98FA4fC5F7868',
  acceptedInvoiceToken: '',
  distributionOperator: '',

  untangled: {
    securitizationManagementProject:
      '0x46eCb5830a08e7faF5c08F5AB440321765A22CAc',
    securitizationPoolValueService:
      '0x704E0e57cbB2bbD364Dc858eE4f98FA4fC5F7868',
    noteTokenVault: '0xD92f2596Dc5dc1DB63158B706930d38AA36B7933',
    distributionAssessor: '', // TODO Should be removed
    distributionTranche: '', // TODO should be removed
    distributionOperator: '', // TODO safe to be removed
    uniqueIdentity: '0xF1B7282B919e9C8A4F774EeeeF87dE94A7f6c1f2',
    loanAssetToken: '0xE29d1F1e1Cc8A3da5CED33518Cd57c0456d57769',
    vault: '0x57DD63aa59F6492FA9144aD9A651382b3d502856',
  },
};

export default contracts;
