<script setup lang="ts">
import useNotifications from '@/composables/useNotifications';
import useWeb3 from '@/services/web3/useWeb3';

interface Props {
  isVisible?: boolean;
}

const { connectSmartAccount, walletState } = useWeb3();
const { addNotification } = useNotifications();

const props = withDefaults(defineProps<Props>(), {
  isVisible: false,
});

const emit = defineEmits(['close']);
const accountName = ref('');

const isConnecting = computed(() => walletState.value === 'connecting');

const handleConnect = async () => {
  try {
    await connectSmartAccount(accountName.value);
    accountName.value = '';
  } catch (error: any) {
    addNotification({
      type: 'error',
      title: 'Failed to create account',
      message: error.message,
    });
  }
};

const onClose = () => {
  accountName.value = '';
  emit('close');
};
</script>

<template>
  <BalModal
    :show="props.isVisible"
    title="Connect to a smart account"
    @close="onClose"
  >
    <h6>Sign Up / In With Passkeys</h6>
    <div>
      <BalTextfield
        v-model="accountName"
        name="accountName"
        label="Account Name"
        class="mt-3 text-gray-400"
        helperText="The account name will be used as username to create new passkey or connect with your existing passkey"
      />
    </div>
    <div class="flex gap-3 items-center mt-3">
      <BalBtn
        :disabled="!accountName"
        color="primary"
        block
        :loading="isConnecting"
        loadingLabel="Authenticating..."
        @click="handleConnect"
      >
        <span>Connect</span>
      </BalBtn>
    </div>
  </BalModal>
</template>
