import { Config } from '../types';
import keys from './keys';
import contracts from './contracts';
import pools from './pools';
import tokenlists from './tokenlists';
import tokens from './tokens';

const config: Config = {
  key: '44787',
  chainId: 44787,
  layerZeroChainId: 110,
  supportsVeBalSync: true,
  chainName: 'Alfajores',
  name: 'Celo Testnet Alfajores',
  shortName: 'Alfajores',
  monorepoName: 'alfajores',
  slug: 'alfajores',
  network: 'alfajores',
  trustWalletNetwork: 'alfajores',
  unknown: false,
  visibleInUI: true,
  testNetwork: true,
  rpc: `https://alfajores-forno.celo-testnet.org`,
  ws: `wss://alfajores-forno.celo-testnet.org/ws`,
  publicRpc: 'https://alfajores-forno.celo-testnet.org',
  explorer: 'https://alfajores.celoscan.io',
  explorerName: 'Alfajores',
  subgraph:
    'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
  balancerApi: 'https://api.balancer.fi',
  poolsUrlV2: '',
  subgraphs: {
    main: [
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-arbitrum-v2',
    ],
    aave: '',
    gauge:
      'https://api.thegraph.com/subgraphs/name/balancer-labs/balancer-gauges-arbitrum',
    blocks:
      'https://api.thegraph.com/subgraphs/name/ianlapham/arbitrum-one-blocks',
  },
  bridgeUrl: 'https://bridge.arbitrum.io/',
  supportsEIP1559: false,
  supportsElementPools: false,
  blockTime: 2,
  nativeAsset: {
    name: 'Celo',
    address: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
    symbol: 'CELO',
    decimals: 18,
    deeplinkId: 'ether',
    logoURI: 'tokens/celo.png',
    minTransactionBuffer: '0.05',
  },
  vaultToken: {
    name: 'Vault',
    address: '0x0000000000000000000000000000000000000000',
    managerAddress: '0x0000000000000000000000000000000000000000',
    symbol: 'VAULT',
    decimals: 18,
    assetDecimals: 6,
    assetSymbol: 'USC',
    assetAddress: '0x0000000000000000000000000000000000000000',
    logoURI: 'tokens/celo.png',
    poolId: 493,
  },
  thirdParty: {
    coingecko: {
      nativeAssetId: 'ethereum',
      platformId: 'alfajores-one',
    },
    apyVision: {
      networkName: 'alfajores',
    },
  },
  addresses: {
    ...contracts,
  },
  pools,
  tokens,
  keys,
  gauges: {
    type: 3,
    weight: 0,
  },
  tokenlists,
  rateProviders: {},
};

export default config;
