import { computed, reactive, Ref, ref } from 'vue';
import { useQuery, UseQueryOptions } from '@tanstack/vue-query';
import useWeb3 from '@/services/web3/useWeb3';

import { UserType } from '@/store/verification';
import { UniqueIdentity } from '@/services/balancer/contracts/contracts/unique-identity';
import { networkConfig } from '@/composables/useNetwork';

/**
 * TYPES
 */
type QueryResponse = {
  userType?: UserType;
};
type QueryOptions = UseQueryOptions<QueryResponse>;
interface QueryInputs {
  isEnabled?: Ref<boolean>;
}

const UserTypeByID = {
  0: UserType.NON_US_INDIVIDUAL,
  1: UserType.US_INDIVIDUAL,
  2: UserType.NON_US_BUSINESS,
  3: UserType.US_BUSINESS,
};

const IDs = Object.keys(UserTypeByID)
  .map(id => Number(id))
  .sort((a, b) => a - b);

export const getUserTypeFromUIDQuery = async (address: string) => {
  const uniqueIdentity = new UniqueIdentity(
    networkConfig.addresses.untangled?.uniqueIdentity || ''
  );

  let balances = await uniqueIdentity.balanceOfBatch(
    [address, address, address, address],
    IDs
  );

  if (balances && Array.isArray(balances)) {
    balances = balances.map(balance => balance.toString());
  }

  for (let i = 0; i < IDs.length; i++) {
    const id = IDs[i];
    if (balances[i] && balances[i] !== '0') {
      const userType = UserTypeByID[id];
      return { userType };
    }
  }

  return {
    userType: undefined,
  };
};

export default function useGetUserTypeFromUIDQuery(inputs?: QueryInputs) {
  const { isEnabled = ref(true) } = inputs || {};

  /**
   * COMPOSABLES
   */
  const { account, isWalletReady } = useWeb3();

  /**
   * COMPUTED
   */
  const enabled = computed(() => isWalletReady.value && isEnabled.value);

  /**
   * QUERY INPUTS
   */
  const queryKey = computed(() => ['uid', account.value]);

  const queryFn = async () => {
    return getUserTypeFromUIDQuery(account.value);
  };

  const queryOptions = reactive({
    enabled,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    staleTime: 0,
  });

  const query = useQuery<QueryResponse>(
    queryKey.value,
    queryFn,
    queryOptions as QueryOptions
  );

  watch(account, () => {
    query.refetch();
  });

  return query;
}
